<template>
  <div class="bg-white text-left pt-2">
    <b-alert :show="errors.length > 0" variant="danger" id="errorMessage">
      <p>{{ $t('task_error_form') }}</p>
      <ul>
        <li class="errorBulletPoints" v-for="(message, index) in errors" :key="index">{{ message }}</li>
      </ul>
    </b-alert>

    <h3 class="text-left d-flex justify-content-between font-weight-bold">{{ $t('categories') }} <span class="d-md-none font-weight-bold cursor-pointer"
                                                                                                       v-on:click="triggerAdvice('categories')">&#63;</span></h3>
    <p class="text-left">{{ $t('create_task.categories.description') }}</p>

    <div v-if="advice.categories" class="alert alert-warning align-items-center d-flex small text-left">
      <i class="fa-regular fa-lightbulb fa-2x pr-3" aria-hidden="true"/>
      <p class="m-0">
        {{ $t('create_task.categories.advice') }}
      </p>
    </div>
    <div>
      <div class="align-items-start d-md-flex flex-col mb-2 mb-md-4">
        <label class="m-0 mb-2 pr-2 text-nowrap">{{ $t('create_task.categories.primary_label') }}</label>
        <div class="d-flex w-100">
          <b-form-select v-model="primaryCategories.selectedParent" class="mr-3 mb-3 mb-md-0" :options="primaryCategories.parent" v-on:change="categoryPrimaryIndex(false)"/>
          <b-form-input list="primaryCategories" :placeholder="$t('create_task.categories.subcategory_placeholder')" v-model="primaryCategories.selected" class="mr-2" :disabled="primaryCategories.selectedParent === null"></b-form-input>
          <datalist id="primaryCategories">
            <option v-for="(category, index) in primaryCategories.options[primaryCategories.selectedParentIndex]" :value="category.text" :key="index">{{ category.text }}</option>
          </datalist>
        </div>
      </div>

      <div class="align-items-start d-md-flex flex-col mb-2">
        <label class="m-0 mb-2 pr-2 text-nowrap" ref="labelAdditional">{{ $t('create_task.categories.secondary_label') }}</label>
        <div class="d-flex w-100">
          <b-form-select v-model="additionalCategories.selectedParent" class="mr-3 mb-3 mb-md-0" :options="additionalCategories.parent" v-on:change="categoryAdditionalIndex(false)"/>
          <b-form-input list="additionalCategories" :placeholder="$t('create_task.categories.subcategory_placeholder')" v-model="additionalCategories.selected" class="mr-2" :disabled="additionalCategories.selectedParent === null"></b-form-input>
          <datalist id="additionalCategories">
            <option v-for="(category, index) in additionalCategories.options[additionalCategories.selectedParentIndex]" :value="category.text" :key="index">{{ category.text }}
            </option>
          </datalist>
        </div>
      </div>
    </div>

    <div v-if="selectedCategories.length > 1">
      <hr/>
      <div>
        <div class="align-items-center mb-2">
          <label class="d-block d-md-inline m-0 pr-2">{{ $t('create_task.categories.selected') }}</label>
          <span class="badge badge-primary p-2 mr-2 mb-2" v-for="(value, index) in this.getSelectedCategories.slice(1)" :key="index">{{ getCategoryName(value) }} <i
            class="fa-solid fa-xmark cursor-pointer" aria-hidden="true" v-on:click="removeCategory(value)"/></span>
        </div>
      </div>
    </div>
    <h3 class="text-left mt-4 d-flex justify-content-between font-weight-bold">
      {{ $t('languages') }}
      <span class="d-md-none font-weight-bold cursor-pointer" v-on:click="triggerAdvice('languages')">&#63;</span>
    </h3>
    <p>{{ $t('create_task.languages.description') }}</p>
    <div v-if="advice.languages" class="alert alert-warning align-items-center d-flex small text-left">
      <i class="fa-regular fa-lightbulb fa-2x pr-3" aria-hidden="true"/>
      <p class="m-0">
        {{ $t('create_task.languages.advice') }}
      </p>
    </div>
    <div>
      <div class="align-items-center d-md-flex mb-2">
        <b-form-input list="selectionLanguages" :placeholder="$t('choose_language')" v-model="selectionLanguages.selected" class="mr-2"></b-form-input>
        <datalist id="selectionLanguages">
          <option v-for="(language, index) in selectionLanguages.options" :key="index">{{ language.text }}</option>
        </datalist>
        <b-button variant="outline-primary" v-on:click="addLanguage()">{{ $t('create_task.languages.add') }}</b-button>
      </div>
    </div>
    <hr/>
    <div>
      <div class="align-items-center mb-2">
        <label class="d-block d-md-inline m-0 pr-2">{{ $t('selected_languages') }}</label>
        <span class="badge badge-primary p-2 mr-2 mb-2" v-for="(value, index) in this.getSelectedLanguages" :key="index">{{ getLanguageName(value) }} <i
          class="fa-solid fa-xmark cursor-pointer" aria-hidden="true" v-on:click="removeLanguage(value)"/></span>
      </div>
    </div>

    <div class="text-right pt-2 pb-2 d-flex flex-md-row-reverse flex-column">
      <b-button v-on:click="validation" class="btn-primary">{{ $t('create_task.create_button') }}</b-button>
      <b-button variant="link" v-on:click="switchSection" class="text-dark mt-2 mt-md-0">{{ $t('create_task.back_button') }}</b-button>
    </div>
  </div>
</template>

<script>
import apiService from '../../apiService'

export default {
  name: 'CreateTaskSection3',
  props: {
    taskCategory: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    taskLanguages: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    hintSwitch: {
      type: Boolean,
      require: true
    },
    errorMessage: {
      type: Array,
      required: false,
      default () {
        return []
      }
    }
  },
  watch: {
    'hintSwitch': function (val) {
      this.triggerAllAdvice(val)
    },
    'errorMessage': function (val, oldVal) {
      if (val !== oldVal) {
        this.errors = val
        window.scrollTo(0, 0)
      }
    },
    'primaryCategories.selected': function () {
      if (this.primaryCategories.selected === this.additionalCategories.selected) {
        this.setError(this.$t('create_task.categories.errors.primary_already_set'))
      } else if (this.errors.includes(this.$t('create_task.categories.errors.primary_already_set'))) {
        this.errors.splice(this.errors.indexOf(this.$t('create_task.categories.errors.primary_already_set')), 1)
      }
    },
    'additionalCategories.selected': function () {
      if (this.primaryCategories.selected === this.additionalCategories.selected) {
        this.setError(this.$t('create_task.categories.errors.primary_already_set'))
      } else if (this.errors.includes(this.$t('create_task.categories.errors.primary_already_set'))) {
        this.errors.splice(this.errors.indexOf(this.$t('create_task.categories.errors.primary_already_set')), 1)
      }
    }
  },
  data () {
    return {
      advice: {
        categories: true,
        languages: true
      },
      errors: [],
      categories: [],
      selectedCategories: [],
      primaryCategories: {
        selected: null,
        selectedParent: null,
        selectedParentIndex: null,
        options: [],
        parent: []
      },
      additionalCategories: {
        selected: null,
        selectedParent: null,
        selectedParentIndex: null,
        options: [],
        parent: []
      },
      selectedLanguages: [],
      languages: [],
      selectionLanguages: {
        selected: null,
        options: []
      }
    }
  },
  created () {
    window.addEventListener('resize', this.resizeHandler)
  },
  destroyed () {
    window.removeEventListener('resize', this.resizeHandler)
  },
  mounted () {
    if (this.$refs.labelAdditional.offsetParent !== null) {
      this.triggerAdvice('categories')
      this.triggerAdvice('languages')
    }
    this.getCategories().then(() => {
      let primaryCategories = this.buildOptionsForCategory
      this.primaryCategories.options = primaryCategories.options
      let primaryParent = primaryCategories.parent.map(a => ({ ...a }))
      primaryParent.unshift({ 'value': null, 'text': this.$t('create_task.categories.placeholder') })
      this.primaryCategories.parent = primaryParent

      let additionalCategories = this.buildOptionsForCategory
      this.additionalCategories.options = additionalCategories.options
      let additionalParent = primaryCategories.parent.map(a => ({ ...a }))
      additionalParent.unshift({ 'value': null, 'text': this.$t('create_task.categories.additional_label') })
      this.additionalCategories.parent = additionalParent

      this.setSelectDefaultMessage()
      if (this.taskCategory.length) {
        this.selectedCategories = []
        this.taskCategory.forEach((item, i) => {
          if (i === 0) {
            this.setPrimaryCategoryData(item)
          } else if (i === 1) {
            this.setPrimaryCategoryData(item, false)
            this.selectedCategories.push(item)
          } else {
            this.selectedCategories.push(item)
          }
        })
        this.categoryPrimaryIndex(true)
      }
    })
    this.getLanguages().then(() => {
      this.selectionLanguages.options = this.buildOptionsForLanguages
    })

    if (this.taskLanguages.length) {
      this.selectedLanguages = this.taskLanguages
    }
  },
  methods: {
    setPrimaryCategoryData (hash, primary = true) {
      for (const [, value] of Object.entries(this.categories)) {
        for (let [, subValue] of Object.entries(value.child_categories)) {
          if (subValue.hash === hash) {
            if (primary) {
              this.primaryCategories.selected = subValue.name
              this.primaryCategories.selectedParent = value.hash
              return true
            } else {
              this.additionalCategories.selected = subValue.name
              this.additionalCategories.selectedParent = value.hash
              return true
            }
          }
        }
      }
      return false
    },
    categoryPrimaryIndex (onload) {
      this.primaryCategories.selectedParentIndex = this.primaryCategories.parent.findIndex(item => item.value === this.primaryCategories.selectedParent) - 1
      if (!onload) {
        this.primaryCategories.selected = null
      }
    },
    categorySecondaryIndex () {
      this.additionalCategories.selectedParentIndex = this.additionalCategories.parent.findIndex(item => item.value === this.additionalCategories.selectedParent) - 1
    },
    categoryAdditionalIndex () {
      this.additionalCategories.selectedParentIndex = this.additionalCategories.parent.findIndex(item => item.value === this.additionalCategories.selectedParent) - 1
      this.additionalCategories.selected = null
    },
    resizeHandler () {
      this.setSelectDefaultMessage()
      if (this.$refs.labelAdditional.offsetParent === null) {
        this.setAdvice('categories', true)
        this.setAdvice('languages', true)
      }
    },
    switchSection () {
      this.$emit('switchSection', 1)
    },
    setAdvice (type, value) {
      this.advice[type] = value
    },
    triggerAdvice (type) {
      this.advice[type] = !this.advice[type]
    },
    triggerAllAdvice (val) {
      for (const [key] of Object.entries(this.advice)) {
        this.advice[key] = val
      }
    },
    setSelectDefaultMessage () {
      if (this.$refs.labelAdditional.offsetParent === null) {
        this.additionalCategories.parent[0]['text'] = this.$t('create_task.categories.additional_label')
      } else {
        this.additionalCategories.parent[0]['text'] = this.$t('create_task.categories.placeholder')
      }
    },
    getCategories () {
      return apiService.getAllCategories().then((data) => {
        this.categories = JSON.parse(JSON.stringify(data.data.data))
        for (let i = 0; i < this.categories.length; i++) {
          this.categories[i].child_categories = this.categories[i].child_categories.filter((category) => !category.divider)
        }
      })
    },
    removeCategory (hash) {
      this.selectedCategories.splice(this.selectedCategories.indexOf(hash), 1)
    },
    getCategoryName (hash) {
      for (const [, value] of Object.entries(this.categories)) {
        for (let [, subValue] of Object.entries(value.child_categories)) {
          if (subValue.hash === hash) {
            return subValue.name
          }
        }
      }
      return ''
    },
    getLanguages () {
      return apiService.getAllLanguages().then((data) => {
        this.languages = data.data['data']
      })
    },
    addLanguage () {
      if (this.selectionLanguages.selected == null) {
        return
      }

      let newSelect = null
      let vm = this
      this.selectionLanguages.options.some(function (value) {
        if (value.text === vm.selectionLanguages.selected) {
          newSelect = value.value
          return true
        }
        return false
      })

      if (newSelect !== null) {
        this.selectedLanguages.push(newSelect)
        this.selectedLanguages = [...new Set(this.selectedLanguages)]
        this.selectionLanguages.selected = null
      }
    },
    getLanguageName (hash) {
      for (const [, value] of Object.entries(this.languages)) {
        if (hash === value.code) {
          return value.name
        }
      }
      return ''
    },
    removeLanguage (hash) {
      this.selectedLanguages.splice(this.selectedLanguages.indexOf(hash), 1)
    },
    setError (message) {
      this.errors = Array.isArray(message) ? message : [message]
      window.scrollTo(0, 0)
    },
    validation () {
      this.errors = []
      let message = []
      let primaryCategory = ''

      if (this.primaryCategories.selected === null) {
        message.push(this.$t('create_task.categories.errors.primary_not_set'))
      } else {
        primaryCategory = this.getCategoryHashes(this.primaryCategories.selected, this.primaryCategories.selectedParentIndex)
        if (primaryCategory === undefined) {
          message.push(this.$t('create_task.categories.errors.primary_not_set'))
        }
      }
      this.categorySecondaryIndex()

      if (this.additionalCategories.selected && (this.additionalCategories.selected !== this.primaryCategories.selected)) {
        let secondaryCategory = this.getCategoryHashes(this.additionalCategories.selected, this.additionalCategories.selectedParentIndex)

        this.selectedCategories.splice(0, 1, secondaryCategory)
        this.selectedCategories = [...new Set(this.selectedCategories)]
      }

      if (this.selectedCategories.indexOf(this.primaryCategories.selected) > -1) {
        message.push(this.$t('create_task.categories.errors.primary_already_set'))
      }

      if (this.selectedLanguages.length === 0) {
        message.push(this.$t('create_task.languages.errors.min'))
      }

      if (message.length) {
        this.setError(message)
        return
      }

      let languages = []
      this.selectedLanguages.forEach(value => {
        languages.push({ 'code': value, 'name_local': this.getLanguageName(value) })
      })

      this.$emit('saveData', {
        categories: [primaryCategory].concat(this.selectedCategories),
        languages: this.selectedLanguages,
        fullLanguages: languages
      })
    },
    getCategoryHashes (data, index) {
      for (let [, value] of Object.entries(this.primaryCategories.options[index])) {
        if (value.text === data) {
          return value.value
        }
      }
    }
  },
  computed: {
    buildOptionsForCategory () {
      let categories = []
      let parentCategories = []

      for (const [, value] of Object.entries(this.categories)) {
        let subCategories = []

        if (value.hash.substring(0, 5) === 'admin') {
          continue
        }

        for (let [, subValue] of Object.entries(value.child_categories)) {
          if (subValue.hash.substring(0, 5) === 'admin') {
            continue
          }
          subCategories.push({ value: subValue.hash, text: subValue.name })
        }
        subCategories.sort((a, b) => a.text.localeCompare(b.text))
        parentCategories.push({ text: value.name, value: value.hash })
        categories.push(subCategories)
      }

      return { 'options': categories, 'parent': parentCategories }
    },
    getSelectedCategories () {
      return this.selectedCategories
    },
    buildOptionsForLanguages () {
      let languages = []

      for (const [, value] of Object.entries(this.languages)) {
        languages.push({ value: value.code, text: value.name })
      }

      return languages
    },
    getSelectedLanguages () {
      return this.selectedLanguages
    }
  }
}
</script>

<style lang="scss" scoped>
@include md-down {
  label {
    margin-bottom: 0.5rem !important;
  }
  button {
    margin-top: 1rem;
    width: 100%;
  }
  hr {
    visibility: hidden;
  }
}

.errorBulletPoints {
  list-style-type: disc;
}
</style>
